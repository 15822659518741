import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

export default function DividendSummary() {
  return (
    <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: 50 }}
      >
        <h2
          style={{
            textAlign: "center",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "0.9rem", sm: "1.25rem" },
          }}
        >
          Dividends
        </h2>
      </Stack>
    </Paper>
  );
}
