import { Container, Typography, Button, Box } from '@mui/material';

export default function LandingPage() {
  return (
    <Container maxWidth="md">
      {/* Hero section */}
      <Box my={4} textAlign="center">
        <Typography variant="h2" component="h1" gutterBottom>
        Simplify Your Finances with Finnave
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
        Aggregating and visualizing financial assets in a single dashboard
        </Typography>
        <Button variant="contained" color="primary" href="/.auth/login/aadb2c">
          Get Started
        </Button>
      </Box>

      {/* Feature section */}
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          Our Features
        </Typography>
        <Typography variant="body1" gutterBottom>
        Asset Aggregation: Get a comprehensive snapshot of your financial health in one place
        </Typography>
        <Typography variant="body1" gutterBottom>
        Dashboard: Interactive charts and graphs help you understand your financial data at a glance
        </Typography>
        <Typography variant="body1" gutterBottom>
        Notifications: Finnave keeps you updated on important financial events
        </Typography>
      </Box>

      {/* Call to action */}
      <Box my={4} textAlign="center">
        <Typography variant="h4" component="h2" gutterBottom>
        Start managing your finances smarter today!
        </Typography>
        <Button variant="contained" color="primary">
          Contact Us
        </Button>
      </Box>
    </Container>
  );
}