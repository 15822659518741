import { useEffect, useState } from "react";
import LandingPage from "../components/homepage/landing";
import { getUserInfo } from "../common/identity";
import AuthenticatedHome from "../components/homepage/authenticatedHome";

export default function Home() {

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    async function fetchUserInfo() {
      const info = await getUserInfo();
      setUserInfo(info);
    }

    fetchUserInfo();
  }, []);

  console.log(userInfo)

  return (
    <>
    {userInfo ? (
      <AuthenticatedHome />
    ) : (
      <LandingPage />
    )}
    </>
  );
}
