import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function DeleteOptionsContract({ openDialog, setOpenDialog, entry, refreshData, setRefreshData }) {

    const [sucessNotification, setSuccessNotification] = React.useState(false);
    const [errorNotification, setErrorNotification] = React.useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleDelete = async () => {

        try {
            var result = await delete_transaction(entry.tid);
            if (result !== 204){
                setErrorNotification(true);
                return;
            }

            setSuccessNotification(true);
            setRefreshData(refreshData + 1);
            setOpenDialog(false);
        }
        catch (error) {
            setErrorNotification(true);
            console.error("Error:", error);
        }
    }

    async function delete_transaction(tid){
        const endpoint = "/data-api/rest/OptionsTransactions/tid/" + tid;
        const response = await fetch(endpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.status;
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessNotification(false);
        setErrorNotification(false);
    }

    //console.log(entry);

    return (
        <>
            <React.Fragment>
                {entry ? <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`❌ ${entry.quantity} | ${entry.symbol} | ${entry.option_type} | ${entry.strike_price}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="error" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog> : <></>}
                <Snackbar
                    open={sucessNotification}
                    autoHideDuration={3000}
                    onClose={handleCloseNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleCloseNotification}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Transaction deleted!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorNotification}
                    autoHideDuration={3000}
                    onClose={handleCloseNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert
                        onClose={handleCloseNotification}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Error, please try again!
                    </Alert>
                </Snackbar>

            </React.Fragment>
        </>
    )
}