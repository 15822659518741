import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { getUserInfo } from "../../common/identity";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import StocksLogo from "../../images/icons/icons8-stock-share-48.png";

export default function StocksHolding() {
  //TODO: read from user settings
  let display_currency = "SGD";

  const [stocksHolding, setStocksHolding] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      var user = await getUserInfo();
      try {
        const response = await fetch(
          `/data-api/rest/EquitiesHolding?user_id=${user.userId}&currency=${display_currency}`
        );
        const data = await response.json();
        console.log(data.value);
        const sortedData = data.value.sort(
          (a, b) => b.total_val_view - a.total_val_view
        );
        setStocksHolding(sortedData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  },[display_currency]);

  const total_stocks_value = stocksHolding.reduce(
    (total, stock) => total + stock.total_val_view,
    0
  );
  const browserLocale = navigator.language || "en-US";

  const formatCurrency = (value, currency, locale = browserLocale) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 50 }}
        >
          <Stack direction="row" spacing={1}>
            <img
              src={StocksLogo}
              style={{
                width: "36px",
                height: "36px",
                display: "block",
                margin: "auto",
              }}
                alt="Stocks Logo"
            />
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 700,
                fontSize: { xs: "0.9rem", sm: "1.25rem" },
              }}
            >
              Stocks
            </h2>
          </Stack>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 500,
              fontSize: { xs: "0.9rem", sm: "1.25rem" },
            }}
          >
            {formatCurrency(total_stocks_value, display_currency)}
          </h3>
        </Stack>

        <Stack alignItems="center" spacing={1}>
          <Paper
            elevation={0}
            sx={{
              height: 335,
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              crollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#ffffff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Grid container spacing={2} p={0}>
              {stocksHolding.map((stock, index) => (
                <Grid item xs={4} height="100" width="100">
                  <Paper
                    elevation={0}
                    key={index}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 2,
                      bgcolor: "grey.100",
                      "&:hover": {
                        bgcolor: "grey.300",
                      },
                    }}
                  >
                    <Stack p={1}>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          alignContent="center"
                          justifyContent="center"
                          alignItems="center"
                          p="0.3rem"
                        >
                          {stock.logo ? (
                            <Grid item xs={4}>
                              <img
                                src={stock.logo}
                                alt={`${stock.symbol} logo`}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "50%",
                                }}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid item xs={8} alignContent="center">
                          <h3
                            style={{
                              textAlign: "end",
                              fontFamily: "Open Sans, sans-serif",
                              fontWeight: 600,
                              fontSize: { xs: "0.3rem", sm: "0.5rem" },
                              margin: "0",
                            }}
                          >
                            {stock.symbol}
                          </h3>
                        </Grid>
                      </Grid>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        px="0.2rem"
                      >
                        <Typography
                          fontSize={{ xs: "0.5rem", sm: "0.7rem" }}
                          style={{
                            textAlign: "end",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: 200,
                            margin: "0",
                          }}
                        >
                          Price
                        </Typography>
                        <Typography
                          fontSize={{ xs: "0.7rem", sm: "1rem" }}
                          style={{
                            textAlign: "end",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: 400,
                            margin: "0",
                          }}
                        >
                          {formatCurrency(stock.price, stock.currency)}
                        </Typography>
                      </Stack>
                      <Stack direction="column" px="0.2rem">
                        <Typography
                          fontSize={{ xs: "0.5rem", sm: "0.7rem" }}
                          style={{
                            textAlign: "end",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: 200,
                            margin: "0",
                          }}
                        >
                          Total Value
                        </Typography>
                        <Typography
                          fontSize={{ xs: "0.7rem", sm: "1rem" }}
                          style={{
                            textAlign: "end",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: 400,
                            margin: "0",
                          }}
                        >
                          {formatCurrency(
                            stock.total_val_view,
                            display_currency
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Stack>
      </Paper>
    </>
  );
}