import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import StocksLogo from '../../images/icons/icons8-stock-share-48.png';
import DividendLogo from '../../images/icons/icons8-dividends-48.png';
import OptionsLogo from '../../images/icons/icons8-equity-security-48.png';
import BatchUploadLogo from '../../images/icons/icons8-xls-96.png';
import Button from '@mui/material/Button';

export default function NewEntry() {
    return (
        <Paper elevation={3} sx={{ borderRadius: 8, p:2, my: 4 }}>
            <Stack spacing={0}>
                <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
                    New Entry
                </h1>
                <Stack direction="row" flexWrap="wrap" justifyContent="center" alignItems="center">
                <Paper elevation={3}
                            sx={{
                                maxWidth: 200,
                                minWidth: 150,
                                maxHeight: 200,
                                minHeight: 150,
                                margin: '25px'
                            }}
                        >
                            <Button href="/add/stocks" fullWidth
                                sx={{
                                    maxWidth: 250,
                                    minWidth: 200,
                                    maxHeight: 250,
                                    minHeight: 200,
                                    margin: 'auto',
                                }}
                            >
                                <Stack spacing={2}>
                                <img src={StocksLogo} alt="Stocks Logo" style={{width: '56px', height: '56px', display: 'block', margin: 'auto'}} />
                                    <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 500, color:'black' }}>
                                        Stocks
                                    </h2>
                                </Stack>
                            </Button>
                        </Paper>
                        <Paper elevation={3}
                            sx={{
                                maxWidth: 200,
                                minWidth: 150,
                                maxHeight: 200,
                                minHeight: 150,
                                margin: '25px'
                            }}
                        >
                            <Button href="/add/dividends" fullWidth
                                sx={{
                                    maxWidth: 250,
                                    minWidth: 200,
                                    maxHeight: 250,
                                    minHeight: 200,
                                    margin: 'auto',
                                }}
                            >
                                <Stack spacing={2}>
                                    <img src={DividendLogo} alt="Dividend Logo" style={{width: '56px', height: '56px', display: 'block', margin: 'auto'}} />
                                    <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 500, color: 'black' }}>
                                        Dividends
                                    </h2>
                                </Stack>
                            </Button>
                        </Paper>
                        <Paper elevation={3}
                            sx={{
                                maxWidth: 200,
                                minWidth: 150,
                                maxHeight: 200,
                                minHeight: 150,
                                margin: '25px'
                            }}
                        >
                            <Button href="/add/options" fullWidth
                                sx={{
                                    maxWidth: 250,
                                    minWidth: 200,
                                    maxHeight: 250,
                                    minHeight: 200,
                                    margin: 'auto',
                                }}
                            >
                                <Stack spacing={2}>
                                <img src={OptionsLogo} alt="Options Logo" style={{width: '56px', height: '56px', display: 'block', margin: 'auto'}} />
                                    <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 500, color: 'black' }}>
                                        Options
                                    </h2>
                                </Stack>
                            </Button>
                        </Paper>     
                        <Paper elevation={3}
                            sx={{
                                maxWidth: 200,
                                minWidth: 150,
                                maxHeight: 200,
                                minHeight: 150,
                                margin: '25px'
                            }}
                        >
                            <Button href="/add/stocks-batch" fullWidth
                                sx={{
                                    maxWidth: 250,
                                    minWidth: 200,
                                    maxHeight: 250,
                                    minHeight: 200,
                                    margin: 'auto',
                                }}
                            >
                                <Stack spacing={2}>
                                <img src={BatchUploadLogo} alt="Options Logo" style={{width: '56px', height: '56px', display: 'block', margin: 'auto'}} />
                                    <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 500, color: 'black' }}>
                                        Batch Upload
                                    </h2>
                                </Stack>
                            </Button>
                            
                        </Paper>                     
                    </Stack>
            </Stack>
        </Paper>

    )
}