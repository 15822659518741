import Grid from "@mui/material/Unstable_Grid2";
import StocksHolding from "../components/stocks/stocksHolding";
import OpenOptionsContract from "../components/options/openOptionsContract";

export default function Holdings() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StocksHolding />
        </Grid>
        <Grid item xs={12} sm={6}>
        <OpenOptionsContract />
        </Grid>
      </Grid>
      
    </>
  );
}
