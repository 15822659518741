import Grid from "@mui/material/Unstable_Grid2";
import OptionsPremiumSummary from "../components/options/optionsPremiumSummary";
import DividendSummary from "../components/stocks/dividendsSummary";

export default function Analysis() {
  return (
    <>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={6} sx={{ height: "400px" }}>
          <DividendSummary />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ height: "400px" }}>
          <OptionsPremiumSummary />
        </Grid>
      </Grid>
    </>
  );
}
