import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import TemplateFile from '../../files/batch_template.csv';
import Papa from 'papaparse';
import { getUserInfo } from "../../common/identity";
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function AddStocksTransactionBatch() {
    const [userInfo, setUserInfo] = useState(null);
    const [jsonData, setJsonData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [sucessNotification, setSuccessNotification] = useState(false);
    const [errorNotification, setErrorNotification] = useState(false);

    useEffect(() => {
        async function fetchUserInfo() {
            const info = await getUserInfo();
            setUserInfo(info);
        }

        fetchUserInfo();
    }, []);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = TemplateFile;
        link.setAttribute('download', 'batch_template.csv');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileChange = (event) => {
        setFileName(event.target.files[0].name);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                Papa.parse(text, {
                    complete: (result) => {
                        setJsonData(result.data); // Update state with JSON data
                    },
                    header: true // Consider the first row of the CSV as headers
                });
            };
            reader.readAsText(file);
        }
    };

    const handleStartProcessing = () => {
        setShowLoading(true);

        try {
            jsonData.forEach(async (row, index) => {

                //console.log(`Processing row ${index}:`, row);

                let entry = row;
                entry.user_id = userInfo.userId;
                entry.transact_amount = parseFloat(entry.transact_unit) * parseFloat(entry.transact_price) + (entry.transact_fees ? parseFloat(entry.transact_fees) : 0);
                entry.account = entry.account.toUpperCase();
                entry.symbol = entry.symbol.toUpperCase();
                entry.currency = entry.currency.toUpperCase();
                entry.transact_unit = parseFloat(entry.transact_unit);
                entry.transact_price = parseFloat(entry.transact_price);
                entry.transact_fees = parseFloat(entry.transact_fees);
                entry.transact_date = new Date(entry.transact_date).toISOString().split('T')[0];

                var result = await add_transaction(entry);
                
                if (result !== 201) {
                    console.error('Error to ingest:', entry);
                    setErrorNotification(true);
                    //setShowLoading(false);
                    //return;
                }
            });
            setSuccessNotification(true);
            setShowLoading(false);

        }
        catch (error) {
            console.error('Error:', error);
            setErrorNotification(true);
            setShowLoading(false);
        }
    };

    async function add_transaction(data) {
        const endpoint = "/data-api/rest/StocksTransactions";
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        //const result = await response.json();
        return response.status;
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessNotification(false);
        setErrorNotification(false);
    }

    const handleReset = (event) => {
        setFileName('');
        setJsonData([]);
        event.target.value = null;
    }

    return (
        <>
            <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4 }}>
                <Box padding={4} autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
                            New Stocks Transaction (Import)
                        </h1>
                        <p style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 400, fontSize: '1rem' }}>
                            First time adding stocks or want to bulk upload? Use this form to add multiple transactions at once.
                        </p>

                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<DownloadIcon />}
                                sx={{ width: '220px' }}
                                onClick={handleDownload}
                            >
                                Download Template
                            </Button>

                            <Divider variant="middle" sx={{ borderStyle: 'dotted', borderColor: 'grey' }} />
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                sx={{ width: '220px' }}
                            >
                                Upload file
                                <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange} />
                            </Button>
                            {fileName && jsonData.length > 0 ?
                                <>
                                    <p style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 400, fontSize: '1rem' }}>
                                        {fileName} is uploaded successfully!
                                    </p>
                                    <p style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 400, fontSize: '1rem' }}>
                                        {jsonData.length} row(s) found in the file.
                                    </p>
                                    <LoadingButton
                                        onClick={handleStartProcessing}
                                        loading={showLoading}
                                        loadingIndicator="Processing…"
                                        variant="contained"
                                    >
                                        <span>Start Processing</span>
                                    </LoadingButton>
                                    <Button
                                        variant='contained'
                                        color='error'
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button>
                                </>



                                :
                                <p style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 400, fontSize: '1rem' }}>
                                    No uploaded file yet.
                                </p>
                            }

                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            <Snackbar
                open={sucessNotification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert
                    onClose={handleCloseNotification}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Transaction added successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorNotification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Missing information or invalid input!
                </Alert>
            </Snackbar>
        </>
    )
}