import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getUserInfo } from "../../common/identity";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import UpdateOptionsContract from "./updateOptionsContract";
import DeleteOptionsContract from "./deleteOptionsContract";
import EmptyImage from "../../images/images/icons8-empty-96.png";
import Box from '@mui/material/Box';

export default function OpenOptionsContract() {
    const [openOptionsContract, setOpenOptionsContract] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [entry, setEntry] = useState(null);
    const [refreshData, setRefreshData] = useState(0);

    const handleEditClick = (entry) => () => {
        //console.log("Edit clicked for id: ", entry.tid);
        setEntry(entry);
        setOpenEditDialog(true);
    };

    const handleDeleteClick = (entry) => () => {
        setEntry(entry);
        setOpenDeleteDialog(true);
    };

    const columns = [
        {
            field: 'symbol', headerName: 'Symbol', flex: 0.1, minWidth: 80,
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center', fontWeight: 600 }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'option_type', headerName: 'Type', flex: 0.15, minWidth: 80,
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    <b>{params.value}</b> | {new Intl.NumberFormat('en-US', { style: 'currency', currency: params.row.currency }).format(params.row.strike_price)}
                </div>
            ),
        },
        {
            field: 'expiry_date', headerName: 'Expiry Date', flex: 0.15, minWidth: 80,
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    {params.value}
                </div>
            ),
        },
        /*         { field: 'strike_price', headerName: 'Strike Price', flex: 0.15, minWidth: 80, headerAlign: 'center',
                renderCell: (params) => (
                    <div style={{ textAlign: 'center' }}>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: params.row.currency }).format(params.value)}
                    </div>
                  ),
                 }, */
        {
            field: 'account', headerName: 'Account', flex: 0.1, minWidth: 80,
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1, minWidth: 50,
            headerAlign: 'center',
            renderCell: (params) => (
                <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
                    <IconButton aria-label="edit" onClick={handleEditClick(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            var user = await getUserInfo();
            try {
                const response = await fetch(`/data-api/rest/OptionsTransactions?$filter=user_id eq '${user.userId}' and close_date eq null&$orderby=expiry_date asc, symbol asc, tid desc`);
                const data = await response.json();
                setOpenOptionsContract(data.value);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [refreshData]);

    function getRowId(row) {
        return row.tid;
    }

    //console.log(openOptionsContract);

    return (
        <>
            <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4 }}>
                <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
                    Open Options Contracts
                </h2>
                {(openOptionsContract && openOptionsContract.length > 0) ?
                    <>
                        <DataGrid
                            getRowId={getRowId}
                            rows={openOptionsContract}
                            columns={columns}
                            pageSizeOptions={[5, 10, 25]}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </> :
                    <>
                    <Stack>
                        <Box
                            component="img"
                            src={EmptyImage}
                            alt="Empty Image"
                            sx={{
                                width: { xs: '96px', sm: '128px' },
                                height: { xs: '96px', sm: '128px' },
                                display: 'block',
                                margin: 'auto'
                            }}
                        />
                        <h2 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 300, fontSize: '1rem' }}>
                            No open options contracts found!
                        </h2>
                    </Stack>
                        
                    </>}
            </Paper>
            <UpdateOptionsContract openDialog={openEditDialog} setOpenDialog={setOpenEditDialog} entry={entry} refreshData={refreshData} setRefreshData={setRefreshData} />
            <DeleteOptionsContract openDialog={openDeleteDialog} setOpenDialog={setOpenDeleteDialog} entry={entry} refreshData={refreshData} setRefreshData={setRefreshData}/>
        </>
    )
}