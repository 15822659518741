import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enGB } from "dayjs/locale/en-gb";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// To do here:
// Update to get user id from context

export default function AddOptionsTransactionOld() {

  const initialEntryState = {
    //id: "",
    transact_date: dayjs().format("YYYY-MM-DD"),
    symbol: "",
    quantity: "",
    strike_price: "",
    transact_price: "",
    exp_date: null,
    option_type: "",
    //credit_debit: null,
    source: "",
    open_comms: "",
  };

  const [entry, setEntry] = useState(initialEntryState);
  const [notification, setNotification] = useState(false);

  const handleChange = (event, toggleValue, name) => {
    //console.log(event)
    if (event.target.name) {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
      });
    } else {
      setEntry({
        ...entry,
        [name]: toggleValue,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    entry.credit_debit =
      parseInt(entry.quantity) * parseInt(entry.buy_sell) * -100 * parseFloat(entry.transact_price);
    entry.id = "20de4bda-5bf9-4329-90e8-7a0115a4bea6";
    entry.open_comms = entry.open_comms ? parseFloat(entry.open_comms) : 0;
    entry.quantity = parseInt(entry.quantity) * parseInt(entry.buy_sell);
    entry.strike_price = parseFloat(entry.strike_price);
    entry.transact_price = parseFloat(entry.transact_price);
    entry.symbol = entry.symbol.toUpperCase();
    entry.source = entry.source.toUpperCase();
    delete entry.buy_sell;
    console.log(entry);
    setEntry(initialEntryState);
    setNotification(true);
    add_transaction(entry);
  };

  async function add_transaction(data) {
    const endpoint = "/data-api/rest/OptionsTransactions";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log("result", result);
  }

  const handleClear = () => {
    setEntry(initialEntryState);
  }

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification(false);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={enGB}>
        <Paper elevation={3} sx={{ borderRadius: 8 }}>
          <Box component="form" onSubmit={handleSubmit} padding={4} autoComplete="off">
            <Stack direction="column" spacing={2}>
              <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
                New Options Transaction (Old)
              </h1>

              <DatePicker
                label="Transaction Date"
                value={entry.transact_date ? dayjs(entry.transact_date) : dayjs()}
                onChange={(newValue) => {
                  setEntry({
                    ...entry,
                    transact_date: newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4} >
                <TextField
                  name="source"
                  label="Account"
                  value={entry.source}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  name="symbol"
                  label="Symbol"
                  value={entry.symbol}
                  onChange={handleChange}
                  fullWidth
                />
              </Stack>


              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4} >
                <ToggleButtonGroup
                  color="primary"
                  value={entry.buy_sell}
                  name="buy_sell"
                  onChange={(event, newValue) => handleChange(event, newValue, "buy_sell")}
                  exclusive
                  fullWidth
                >
                  <ToggleButton value={1}>BUY</ToggleButton>
                  <ToggleButton value={-1}>SELL</ToggleButton>
                </ToggleButtonGroup>
                <TextField
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={entry.quantity}
                  onChange={handleChange}
                  inputProps={{ min: "1" }}
                  error={entry.quantity !== "" && entry.quantity < 1}
                  helperText={entry.quantity !== "" && entry.quantity < 1 ? "Quantity must be at least 1" : ""}
                  fullWidth
                />
                <ToggleButtonGroup
                  color="secondary"
                  value={entry.option_type}
                  name="option_type"
                  onChange={(event, newValue) => handleChange(event, newValue, "option_type")}
                  exclusive
                  fullWidth
                >
                  <ToggleButton value="CALL">Call</ToggleButton>
                  <ToggleButton value="PUT">Put</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4} >
                <TextField
                  name="strike_price"
                  label="Strike Price"
                  type="number"
                  value={entry.strike_price}
                  onChange={handleChange}
                  inputProps={{ min: "0" }}
                  error={entry.strike_price !== "" && entry.strike_price <= 0}
                  helperText={entry.strike_price !== "" && entry.strike_price <= 0 ? "Strike price must be greater than 0" : ""}
                  fullWidth
                />
                <DatePicker
                  label="Expiry Date"
                  value={entry.exp_date ? dayjs(entry.exp_date) : null}
                  onChange={(newValue) => {
                    setEntry({
                      ...entry,
                      exp_date: newValue ? newValue.format("YYYY-MM-DD") : null,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="DD/MM/YYYY"
                  fullWidth
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4} >
                <TextField
                  name="transact_price"
                  label="Transact Price"
                  type="number"
                  value={entry.transact_price}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ min: "0" }}
                  error={entry.transact_price !== "" && entry.transact_price <= 0}
                  helperText={entry.transact_price !== "" && entry.transact_price <= 0 ? "Transact price must be greater than 0" : ""}
                />
                <TextField
                  name="open_comms"
                  label="Fees"
                  type="number"
                  value={entry.open_comms}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ min: "0" }}
                  error={entry.open_comms !== "" && entry.open_comms < 0}
                  helperText={entry.open_comms !== "" && entry.open_comms < 0 ? "Fees must be at least 0" : ""}
                />
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4} >
                <Button variant="contained" onClick={handleSubmit} >
                  Add
                </Button>
                <Button variant="contained" onClick={handleClear} color="error">
                  Clear
                </Button>
              </Stack>


            </Stack>
          </Box>
        </Paper>
        <Snackbar open={notification} autoHideDuration={3000} onClose={handleCloseNotification}>
          <Alert
            onClose={handleCloseNotification}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Transaction added successfully!
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </>
  );
}
