import TransactHistory from "./transactHistory";
import Grid from "@mui/material/Unstable_Grid2";

export default function AuthenticatedHome() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ height: '400px' }}>
          <TransactHistory />
        </Grid>
      </Grid>
    </>
  );
}
