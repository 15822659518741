import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { getUserInfo } from "../../common/identity";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import StocksLogo from "../../images/icons/icons8-stock-share-48.png";
import DividendLogo from "../../images/icons/icons8-dividends-48.png";
import OptionsLogo from "../../images/icons/icons8-equity-security-48.png";

export default function TransactHistory() {
  const [historyDuration, setHistoryDuration] = useState(7);
  const [transactHistoryObject, setTransactHistoryObject] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      var user = await getUserInfo();
      try {
        const response = await fetch(
          `/data-api/rest/TransactHistory?user_id=${user.userId}&duration=${historyDuration}`
        );
        const data = await response.json();
        setTransactHistoryObject(data.value);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [historyDuration]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 50 }}
        >
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "0.9rem", sm: "1.25rem" },
            }}
          >
            Recent Activities
          </h2>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 50, display: { xs: "none", sm: "block" } }}
          >
            <Button
              onClick={() => setHistoryDuration(7)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Week
            </Button>
            <Button
              onClick={() => setHistoryDuration(30)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Month
            </Button>
            <Button
              onClick={() => setHistoryDuration(90)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Quarter
            </Button>
          </ButtonGroup>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 40, display: { xs: "block", sm: "none" } }}
          >
            <Button
              onClick={() => setHistoryDuration(7)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              W
            </Button>
            <Button
              onClick={() => setHistoryDuration(30)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              M
            </Button>
            <Button
              onClick={() => setHistoryDuration(90)}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Q
            </Button>
          </ButtonGroup>
        </Stack>

        <Stack alignItems="center" spacing={1}>
          <Paper
            elevation={0}
            sx={{
              height: 335,
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              crollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#ffffff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Stack alignItems="center" spacing={1} px="1">
              {transactHistoryObject.map((item, index) => (
                <Box
                  sx={{
                    width: "100%",
                    height: 50,
                    borderRadius: 2,
                    bgcolor: "grey.100",
                    "&:hover": {
                      bgcolor: "grey.300",
                    },
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    p={1}
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid item xs={9} sm={6} height="40">
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box
                          component="img"
                          alt="logo"
                          src={
                            item.type === "EQUITIES"
                              ? StocksLogo
                              : item.type === "DIVIDEND"
                              ? DividendLogo
                              : OptionsLogo
                          }
                          sx={{
                            width: { xs: "32px", sm: "28px" },
                            height: { xs: "32px", sm: "28px" },
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            textAlign: "start",
                            fontWeight: 500,
                            alignContent: "center",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {item.symbol}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      sm={3}
                      sx={{
                        display: { xs: "none", sm: "none", md: "block" },
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "end",
                          fontWeight: 400,
                          alignContent: "center",
                        }}
                      >
                        ${Math.round(item.amount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: 400,
                          height: "40px",
                          alignContent: "center",
                          fontSize: { xs: "0.8rem", sm: "1rem" },
                        }}
                      >
                        {dayjs(item.transact_date).format("YYYY-MM-DD")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Stack>
          </Paper>
        </Stack>
      </Paper>
    </>
  );
}
