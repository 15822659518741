import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AnalysisLogo from "../../images/icons/icons8-financial-growth-analysis-48.png";
import HoldingsLogo from "../../images/icons/icons8-cashbook-48.png";
import NewEntryLogo from "../../images/icons/icons8-add-dollar-48.png";
import HomeLogo from "../../images/icons/icons8-home-page-48.png";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export default function Sidebar() {
  return (
    <Paper
      elevation={3}
      sx={{
        position: "sticky",
        top: 75,
        borderRadius: 8,
        width: { xs: "100%", sm: "90%" },
        height: {
          xs: "5vh",
          sm: "88vh",
        },
        my: {
          xs: 2,
          sm: 4,
        },
      }}
    >
      <Stack
        spacing={4}
        paddingTop={{ xs: 0, sm: 4 }}
        direction={{ xs: "row", sm: "column" }}
        alignItems="center"
      >
        <Button fullWidth href="/">
          <Stack spacing={1} direction="row" alignItems="center">
            <Tooltip title="Home">
              <Box
                component="img"
                src={HomeLogo}
                alt="Home Logo"
                sx={{
                  width: { xs: "32px", sm: "28px" },
                  height: { xs: "32px", sm: "28px" },
                  display: "block",
                  margin: "auto",
                }}
              />
            </Tooltip>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 600,
                color: "black",
                fontSize: "1.1rem",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              Home
            </Typography>
          </Stack>
        </Button>
        <Button fullWidth href="/holdings">
          <Stack spacing={1} direction="row" alignItems="center">
            <Tooltip title="Holdings">
              <Box
                component="img"
                src={HoldingsLogo}
                alt="Holdings Logo"
                sx={{
                  width: { xs: "32px", sm: "28px" },
                  height: { xs: "32px", sm: "28px" },
                  display: "block",
                  margin: "auto",
                }}
              />
            </Tooltip>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 600,
                color: "black",
                fontSize: "1.1rem",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              Holdings
            </Typography>
          </Stack>
        </Button>
        <Button fullWidth href="/analysis">
          <Stack spacing={1} direction="row" alignItems="center">
            <Tooltip title="Analysis">
              <Box
                component="img"
                src={AnalysisLogo}
                alt="Analysis Logo"
                sx={{
                  width: { xs: "32px", sm: "28px" },
                  height: { xs: "32px", sm: "28px" },
                  display: "block",
                  margin: "auto",
                }}
              />
            </Tooltip>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 600,
                color: "black",
                fontSize: "1.1rem",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              Analysis
            </Typography>
          </Stack>
        </Button>
        <Button fullWidth href="/add">
          <Stack spacing={1} direction="row" alignItems="center">
            <Tooltip title="New Entry">
              <Box
                component="img"
                src={NewEntryLogo}
                alt="New Entry Logo"
                sx={{
                  width: { xs: "32px", sm: "28px" },
                  height: { xs: "32px", sm: "28px" },
                  display: "block",
                  margin: "auto",
                }}
              />
            </Tooltip>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 600,
                color: "black",
                fontSize: "1.1rem",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              New Entry
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Paper>
  );
}
