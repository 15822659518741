import { useParams } from 'react-router-dom';
import AddDividendsTransaction from '../components/stocks/addDividendTransaction';
import AddStocksTransaction from '../components/stocks/addStocksTransaction';
import AddOptionsTransaction from '../components/options/addOptionsTransaction';
import AddStocksTransactionBatch from '../components/stocks/addStocksTransactionBatch';
import AddOptionsTransactionOld from '../components/options/addOptionsTransactionOld';
import NewEntry from '../components/add_page/newEntry';


export default function AddTransaction() {
    const { param } = useParams();

    return (
        <>
            {
                (() => {
                    switch (param) {
                        case 'options':
                            return <AddOptionsTransaction />;
                        case 'stocks':
                            return <AddStocksTransaction />;
                        case 'dividends':
                            return <AddDividendsTransaction />;
                        case 'stocks-batch':
                            return <AddStocksTransactionBatch />;
                            case 'options-old':
                                return <AddOptionsTransactionOld />;
                        default:
                            return (
                            <>
                            <NewEntry />
                            </>
                        );
                    }
                })()
            }
        </>
    )
}