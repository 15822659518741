import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrimarySearchAppBar from './components/common/navbar';
import Sidebar from './components/common/sidebar';
import Container from '@mui/material/Container';
import Home from './pages/home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddTransaction from './pages/addTransaction';
import Grid from '@mui/material/Unstable_Grid2'; 
import Analysis from './pages/analysis';
import Holdings from './pages/holdings';

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <PrimarySearchAppBar />
          <Grid container>
            <Grid item xs={12} sm={2}>
            <Sidebar />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/add/:param?" element={<AddTransaction />} />
                <Route exact path="/holdings" element={<Holdings />} />
                <Route exact path="/analysis" element={<Analysis />} />
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
