import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from "@mui/material/Paper";
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enGB } from "dayjs/locale/en-gb";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function UpdateOptionsContract({ openDialog, setOpenDialog, entry, refreshData, setRefreshData}) {

    const [item, setItem] = React.useState({});
    const [sucessNotification, setSuccessNotification] = React.useState(false);
    const [errorNotification, setErrorNotification] = React.useState(false);

    useEffect(() => {
        setItem(entry);
        setItem({
            ...entry,
            is_exercised: 0,
        });
    }, [entry]);

    const handleClose = () => {
        setOpenDialog(false);
        setItem({});
    }

    const handleChange = (event, toggleValue, name) => {
        if (event.target.name) {
            setItem({
                ...item,
                [event.target.name]: event.target.value,
            });
        } else {
            setItem({
                ...item,
                [name]: toggleValue,
            });
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        item.close_price = item.close_price ? parseFloat(item.close_price).toFixed(3) : 0;
        item.close_date = item.close_date ? dayjs(item.close_date).format("YYYY-MM-DD") : null;
        item.close_fees = item.close_fees ? parseFloat(item.close_fees).toFixed(2) : 0;
        item.premium = (((item.transact_price - item.close_price) * entry.quantity * -100) + (item.is_exercised * (item.option_type === "CALL" ? -100 : 100) * item.strike_price * item.quantity)).toFixed(2);
        item.is_exercised = (item.is_exercised === 1) ? true : false;
        //console.log(item);
        try {
            var result = await update_transaction(item);
            console.log("Result:", result);
            if (result !== 200) {
                setErrorNotification(true);
                return;
            }
            setSuccessNotification(true);
            setRefreshData(refreshData + 1);
            setOpenDialog(false);
            setItem({});
        }
        catch (error) {
            setErrorNotification(true);
            console.error("Error:", error);
        }
    }

    async function update_transaction(data) {
        const endpoint = "/data-api/rest/OptionsTransactions/tid/" + data.tid;
        delete data.tid;
        console.log(data);
        const response = await fetch(endpoint, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return response.status;
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessNotification(false);
        setErrorNotification(false);
    }

    //console.log(entry);

    return (
        <>
            <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={enGB}>
                    {item ? <Dialog
                        open={openDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                            component: Paper,
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">{`[Update] ${item.quantity} ${item.symbol} | ${item.option_type} | ${item.strike_price}`}</DialogTitle>
                        <DialogContent>
                            {/*                         <DialogContentText id="alert-dialog-description">
                            Are you sure you want to update {entry.symbol} options contract?
                        </DialogContentText> */}
                            <Box component="form" onSubmit={handleSubmit} padding={4} autoComplete="off">
                                <Stack direction="column" spacing={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={item.is_exercised}
                                                name="is_exercised"
                                                onChange={(event, newValue) => handleChange(event, newValue, "is_exercised")}
                                                exclusive
                                                fullWidth
                                                required
                                                label="Is Exercised?"
                                            >
                                                <ToggleButton value={0}>❌ Exercise</ToggleButton>
                                                <ToggleButton value={1}>Exercise</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <DatePicker
                                                    label="Close Date"
                                                    value={item.close_date ? dayjs(item.close_date) : null}
                                                    onChange={(newValue) => {
                                                        setItem({
                                                            ...item,
                                                            close_date: newValue ? newValue.format("YYYY-MM-DD") : null,
                                                        });
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    inputFormat="DD/MM/YYYY"
                                                    minDate={dayjs(item.transact_date)}
                                                    maxDate={dayjs(item.expiry_date)}
                                                    required
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="close_price"
                                                label="Close Price"
                                                type="number"
                                                value={item.close_price}
                                                onChange={handleChange}
                                                inputProps={{ min: "0" }}
                                                error={item.close_price !== "" && item.close_price < 0}
                                                helperText={item.close_price !== "" && item.close_price < 0 ? "Close price must be greater than or equal 0" : ""}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="close_fees"
                                                label="Close Fees"
                                                type="number"
                                                value={item.close_fees}
                                                onChange={handleChange}
                                                inputProps={{ min: "0" }}
                                                error={item.close_fees !== "" && item.close_fees < 0}
                                                helperText={item.close_fees !== "" && item.close_fees < 0 ? "Close price must be greater than or equal 0" : ""}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <TextField
                                                id="notes"
                                                name="remarks"
                                                label="Notes"
                                                multiline
                                                rows={3}
                                                value={item.remarks}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary" autoFocus>
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog> : <></>}
                </LocalizationProvider>
                <Snackbar
                    open={sucessNotification}
                    autoHideDuration={3000}
                    onClose={handleCloseNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleCloseNotification}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Transaction updated successfully!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorNotification}
                    autoHideDuration={3000}
                    onClose={handleCloseNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert
                        onClose={handleCloseNotification}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Missing information or invalid input!
                    </Alert>
                </Snackbar>
            </React.Fragment>
        </>
    )
}