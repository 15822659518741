import React, { useState, useEffect } from "react";
import { useDebounce } from 'use-debounce';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enGB } from "dayjs/locale/en-gb";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';
import { getUserInfo } from "../../common/identity";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export default function AddOptionsTransaction() {

  const initialEntryState = {
    user_id: "",
    symbol: "",
    currency: "",
    transact_date: dayjs().format("YYYY-MM-DD"),
    option_type: "CALL",
    strike_price: "",
    expiry_date: "",
    transact_price: "",
    quantity: "",
    open_fees: "",
    premium: "",
    account: "",
    remarks: "",
    buy_sell: 1,
  };

  const [entry, setEntry] = useState(initialEntryState);
  const [userInfo, setUserInfo] = useState(null);
  const [sucessNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 300);
  const [options, setOptions] = useState([]);
  const [currency, setCurrency] = useState("");
  const [symbolList, setSymbolList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUserInfo() {
      const info = await getUserInfo();
      setUserInfo(info);
    }

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (debouncedInputValue) {
        setLoading(true);
        fetch(`/api/SearchSymbol?search=${debouncedInputValue}`)
            .then((response) => response.json())
            .then((data) => {
                setOptions(data);
                const symbols = data.map(item => item.symbol);
                setSymbolList(symbols);
                setLoading(false);
            });
    } else {
        setOptions([]);
    }
}, [debouncedInputValue]);

  const handleChange = (event, toggleValue, name) => {
    if (event.target.name) {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
      });
    } else {
      setEntry({
        ...entry,
        [name]: toggleValue,
      });
    }
  };

  const handleSubmit = async (event) => {
    entry.symbol = inputValue;
    entry.currency = currency;

    console.log(entry);
    // check entry.account is not empty
    if (entry.account === "" || entry.symbol === "") {
      setErrorNotification(true);
      return;
    }

    if (entry.strike_price === "" || entry.strike_price <= 0) {
      setErrorNotification(true);
      return;
    }

    if (entry.quantity === "" || entry.quantity <= 0) {
      setErrorNotification(true);
      return;
    }

    if (entry.transact_price === "" || entry.transact_price <= 0) {
      setErrorNotification(true);
      return;
    }

    if (entry.expiry_date === "") {
      setErrorNotification(true);
      return;
    }

    event.preventDefault();
    entry.account = entry.account.toUpperCase();
    entry.symbol = entry.symbol.toUpperCase();
    entry.strike_price = parseFloat(entry.strike_price).toFixed(2);
    entry.transact_price = parseFloat(entry.transact_price);
    entry.quantity = parseInt(entry.quantity) * parseInt(entry.buy_sell);
    entry.open_fees = entry.open_fees ? parseFloat(entry.open_fees) : 0;
    entry.premium = (entry.quantity * entry.transact_price * -100).toFixed(2);
    entry.user_id = userInfo.userId;
    entry.currency = currency;
    delete entry.buy_sell;
    delete entry.undefined;

    //console.log(entry);

     try {
      var result = await add_transaction(entry);
      console.log("Result:", result);
      if (result !== 201) {
        setErrorNotification(true);
        return;
      }
      setSuccessNotification(true);
      setEntry(initialEntryState);
      setInputValue('');
      setCurrency('');
      setOptions([]);
      setSymbolList([]);
    }
    catch (error) {
      console.error("Error:", error);
    } 
  };

  async function add_transaction(data) {
    const endpoint = "/data-api/rest/OptionsTransactions";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.status;
  }

  const handleClear = () => {
    setEntry(initialEntryState);
    setInputValue('');
    setCurrency('');
    setOptions([]);
    setSymbolList([]);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessNotification(false);
    setErrorNotification(false);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={enGB}>
      <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4 }}>
        <Box component="form" onSubmit={handleSubmit} padding={4} autoComplete="off">
          <Stack direction="column" spacing={2}>
            <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
              New Options Transaction
            </h1>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <DatePicker
                    label="Transaction Date"
                    value={entry.transact_date ? dayjs(entry.transact_date) : dayjs()}
                    onChange={(newValue) => {
                      setEntry({
                        ...entry,
                        transact_date: newValue
                          ? newValue.format("YYYY-MM-DD")
                          : null,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="account"
                  label="Account"
                  value={entry.account}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {/*                 <TextField
                  name="symbol"
                  label="Symbol"
                  value={entry.symbol}
                  onChange={handleChange}
                  fullWidth
                  required
                /> */}
                <Autocomplete
                  name="symbol"
                  freeSolo
                  value={inputValue}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setLoading(true);
                    const selectedOption = options.find(option => option.symbol === newInputValue);
                    if (selectedOption) {
                      setCurrency(selectedOption.currency);
                    }
                    setLoading(false);
                  }}
                  options={symbolList}
                  fullWidth
                  required
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Symbol"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <ToggleButtonGroup
                  color="secondary"
                  value={entry.option_type}
                  name="option_type"
                  onChange={(event, newValue) => handleChange(event, newValue, "option_type")}
                  exclusive
                  fullWidth
                >
                  <ToggleButton value="CALL">Call</ToggleButton>
                  <ToggleButton value="PUT">Put</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="strike_price"
                  label={currency ? `Strike Price (${currency})` : "Strike Price"}
                  type="number"
                  value={entry.strike_price}
                  onChange={handleChange}
                  inputProps={{ min: "0" }}
                  error={entry.strike_price !== "" && entry.strike_price <= 0}
                  helperText={entry.strike_price !== "" && entry.strike_price <= 0 ? "Strike price must be greater than 0" : ""}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <DatePicker
                    label="Expiry Date"
                    value={entry.expiry_date ? dayjs(entry.expiry_date) : null}
                    onChange={(newValue) => {
                      setEntry({
                        ...entry,
                        expiry_date: newValue ? newValue.format("YYYY-MM-DD") : null,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs()}
                    required
                  />
                </Stack>

              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={2}>
                <ToggleButtonGroup
                  color="primary"
                  value={entry.buy_sell}
                  name="buy_sell"
                  onChange={(event, newValue) => handleChange(event, newValue, "buy_sell")}
                  exclusive
                  fullWidth
                  required
                >
                  <ToggleButton value={1}>BUY</ToggleButton>
                  <ToggleButton value={-1}>SELL</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={entry.quantity}
                  onChange={handleChange}
                  inputProps={{ min: "0" }}
                  error={entry.quantity !== "" && entry.quantity < 0}
                  helperText={entry.quantity !== "" && entry.quantity < 0 ? "Quantity must be greater than 0" : ""}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="transact_price"
                  label="Price"
                  type="number"
                  value={entry.transact_price}
                  onChange={handleChange}
                  inputProps={{ min: "0" }}
                  error={entry.transact_price !== "" && entry.transact_price <= 0}
                  helperText={entry.transact_price !== "" && entry.transact_price <= 0 ? "Quantity must be greater than 0" : ""}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  name="open_fees"
                  label="Fees"
                  type="number"
                  value={entry.open_fees}
                  onChange={handleChange}
                  inputProps={{ min: "0" }}
                  error={entry.open_fees !== "" && entry.open_fees < 0}
                  helperText={entry.open_fees !== "" && entry.open_fees < 0 ? "Quantity must be greater than 0" : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  id="notes"
                  name="remarks"
                  label="Notes"
                  multiline
                  rows={3}
                  value={entry.remarks}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4} >
              <Button variant="contained" onClick={handleSubmit} >
                Add
              </Button>
              <Button variant="contained" onClick={handleClear} color="error">
                Clear
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <Snackbar
        open={sucessNotification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={handleCloseNotification}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Transaction added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorNotification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Missing information or invalid input!
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  )
}