import React, { useState, useEffect } from "react";
import { useDebounce } from 'use-debounce';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enGB } from "dayjs/locale/en-gb";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';
import { getUserInfo } from "../../common/identity";
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddDividendsTransaction() {
    const initialEntryState = {
        user_id: "",
        symbol: "",
        currency: "",
        amount: "",
        payable_date: dayjs().format("YYYY-MM-DD"),
        account: ""
    };

    const [entry, setEntry] = useState(initialEntryState);
    const [sucessNotification, setSuccessNotification] = useState(false);
    const [errorNotification, setErrorNotification] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [debouncedInputValue] = useDebounce(inputValue, 300);
    const [options, setOptions] = useState([]);
    const [currency, setCurrency] = useState("");
    const [symbolList, setSymbolList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchUserInfo() {
            const info = await getUserInfo();
            setUserInfo(info);
        }

        fetchUserInfo();
    }, []);

    useEffect(() => {
        if (debouncedInputValue) {
            setLoading(true);
            fetch(`/api/SearchSymbol?search=${debouncedInputValue}`)
                .then((response) => response.json())
                .then((data) => {
                    setOptions(data);
                    const symbols = data.map(item => item.symbol);
                    setSymbolList(symbols);
                    setLoading(false);
                });
        } else {
            setOptions([]);
        }
    }, [debouncedInputValue]);

    const handleChange = (event, toggleValue, name) => {
        //console.log(event)
        setEntry({
            ...entry,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        entry.symbol = inputValue;
        entry.currency = currency;

        if (entry.account === "" || entry.symbol === "") {
            setErrorNotification(true);
            return;
        }

        if (entry.amount === "" || entry.amount <= 0) {
            setErrorNotification(true);
            return;
        }

        event.preventDefault();
        entry.symbol = entry.symbol.toUpperCase();
        entry.account = entry.account.toUpperCase();
        entry.amount = parseFloat(entry.amount);
        entry.user_id = userInfo.userId;
        delete entry.undefined;


        //console.log(entry);

          try {
            var result = await add_transaction(entry);
            console.log("Result:", result);
            if (result !== 201) {
                setErrorNotification(true);
                return;
            }
            setSuccessNotification(true);
            setEntry(initialEntryState);
            setInputValue('');
            setCurrency('');
            setOptions([]);
            setSymbolList([]);
        }
        catch (error) {
            console.error("Error:", error);
        }
    };

    async function add_transaction(data) {
        const endpoint = "/data-api/rest/Dividends";
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        //const result = await response.json();
        return response.status;
    }

    const handleClear = () => {
        setEntry(initialEntryState);
        setInputValue('');
        setCurrency('');
        setOptions([]);
        setSymbolList([]);
    };

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessNotification(false);
        setErrorNotification(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={enGB}>
            <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4 }}>
                <Box component="form" onSubmit={handleSubmit} padding={4} autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontWeight: 700 }}>
                            New Dividend Entry
                        </h1>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={3}>
                                <Stack>
                                    <DatePicker
                                        label="Payable Date"
                                        value={entry.payable_date ? dayjs(entry.payable_date) : dayjs()}
                                        onChange={(newValue) => {
                                            setEntry({
                                                ...entry,
                                                payable_date: newValue
                                                    ? newValue.format("YYYY-MM-DD")
                                                    : null,
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="account"
                                    label="Account"
                                    value={entry.account}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {/*                                 <TextField
                                    name="symbol"
                                    label="Symbol"
                                    value={entry.symbol}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                /> */}
                                <Autocomplete
                                    name="symbol"
                                    freeSolo
                                    value={inputValue}
                                    onChange={(event, newValue) => {
                                        handleChange(event, newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                        setLoading(true);
                                        const selectedOption = options.find(option => option.symbol === newInputValue);
                                        if (selectedOption) {
                                            setCurrency(selectedOption.currency);
                                        }
                                        setLoading(false);
                                    }}
                                    options={symbolList}
                                    fullWidth
                                    required
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Symbol"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="amount"
                                    label={currency ? `Amount (${currency})` : "Amount"}
                                    value={entry.amount}
                                    onChange={handleChange}
                                    error={entry.amount !== "" && entry.amount <= 0}
                                    helperText={entry.amount !== "" && entry.amount <= 0 ? "Transaction unit must be greater than 0" : ""}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4} >
                            <Button variant="contained" onClick={handleSubmit} >
                                Add
                            </Button>
                            <Button variant="contained" onClick={handleClear} color="error">
                                Clear
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            <Snackbar
                open={sucessNotification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert
                    onClose={handleCloseNotification}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Entry added successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorNotification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Missing information or invalid input!
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
}